import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import SectionTitle from "./sectionTitle"
import { StaticImage } from "gatsby-plugin-image"

const Section = styled('section')`
    min-height: 20rem;
    background-color: white;
    padding: 4rem;
    
    div {
        max-width: 1200px;
        margin: 0 auto;
        
    }

    @media screen and (max-width: 640px) {
            
            padding: 0;
    }

    .survey-main {
        display: grid;
        place-items: center center;

        @media screen and (max-width: 640px) {
            width: 90%;
            padding: 0;
        }
    }

`

const Survey = () => (
  <Section className="review" id="review" css={css`
 
  `}>
      <SectionTitle subTitle="How Did We Do" mainTitle="Customer Survey" />
      <div className="survey-main">
            {/* <!-- Reviews --> */}

                <iframe 
                    src="https://buildertrend.net/Surveys/ReviewFrame.aspx?builderID=76004" 
                    scrolling="no" 
                    id="btIframe" 
                    css={css`
                        background:transparent;
                        border:0px;
                        margin:0 auto;
                        width:100%;
                    `}
                ></iframe> 

            {/* <!-- Reviews --> */}
      </div>
  </Section>
)

export default Survey
