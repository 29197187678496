import * as React from "react"
import { css } from "@emotion/react"

const styles = css`
    margin: 0 auto;
    max-width: 800px;
    padding: 2rem 1rem;
`

const SubpageWrapper = ({children}) => (
    <div css={styles}>{children}</div>
)

export default SubpageWrapper
