import * as React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"

const wrapperCSS = css`
    display: grid;
    background-color: lightblue;
    height: 10vh; //TODO update after fixing header height
`;

const h1Style = css`
    font-size: clamp(2rem, -0.875rem + 8.5vw, 4rem);
    color: white;
    text-align: center;
`;

const SubpageHero = (props) => {
    const {h1} = props;
    
    let image = "../images/interior.jpeg";
    let teamPhoto = "../images/team in front of truck.jpg";
    return (
        <section css={wrapperCSS}>
            <StaticImage
                css={css`
                grid-area: 1/1;
                `}
                layout="fullWidth"
                // This is a presentational image, so the alt should be an empty string
                alt=""
                // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                src={ teamPhoto }
                formats={["auto", "webp", "avif"]}
            />
            {/* opacity layer */}
            <div css={css`
                display: grid;
                grid-area: 1/1;
                position: relative;
                background-color: #112413;
                opacity: 0.4;
            `}></div>
            {/* content layer */}
            <div
            css={css`
                display: grid;
                grid-area: 1/1;
                position: relative;
                align-items: center;
                max-width: 1200px;
                width: 100%;
                margin: 0 auto;
            `}
            >
                
                    <h1 css={h1Style}>{h1}</h1>
                
            </div>
        </section>
)}


export default SubpageHero
