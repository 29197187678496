import * as React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

const SectionTitle = (props) => {
    const {subTitle, mainTitle} = props
    return (

        <div css={css`
            text-align:center;
        `}>
        <p css={css`
            font-size: clamp(0.70rem, -0.9rem + 5vw, 1.25rem);
            text-transform: uppercase;
        `}>{subTitle}</p>
      <h2 css={css`
        font-size: clamp(1.25rem, -0.875rem + 8.5vw, 3.25rem);
        text-align: center;
        margin-top: -1.5rem;
        margin-bottom: 4rem;
        color: #0192ff;

        @media screen and (max-width: 640px) {
            margin-bottom: 2rem;
        }
        `}>{mainTitle}</h2>
    </div>
        )
}
  
  
  export default SectionTitle

