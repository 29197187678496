import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Survey from "../components/survey"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubpageHero from "../components/subPageHero"
import SubpageWrapper from "../components/subpageWrapper"

const SurveyPage = () => (
  <Layout>
    <Seo title="How Did We Do" />
    <SubpageHero h1="Rate Us" />
    <SubpageWrapper>
      <Survey />
    </SubpageWrapper>
  </Layout>
)

export default SurveyPage
